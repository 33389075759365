/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/order */
import appConfig from '../../configs/app';
import _, { each } from 'lodash';

const columns = [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'รหัสสินค้า',
    value: 'type_code',
  },
  {
    label: 'ขื่อสินค้า',
    value: 'name',
  },
  {
    label: 'หน่วยสินค้า',
    value: 'unit',
  },
  {
    label: 'ประเภทสินค้า',
    value: (col) => col?.brand?.name,
  },
  {
    label: 'หน่วยสินค้า',
    value: (col) => col?.product_type?.name,
  },
  {
    label: 'รูปภาพ',
    value: (col) => {
      _.map(col, (each) => {
        each?.images?.url;
      });
    },
  },
  {
    label: 'จำนวนคงเหลือ',
    value: 'inventory',
  },
  {
    label: 'ราคาต้นทุนต่อหน่วย',
    value: 'cost_price',
  },
  {
    label: 'ราคาขายต่อหน่วย',
    value: 'price',
  },
  {
    label: 'ราคาต้นทุนรวมในคลัง',
    value: 'total_cost_price',
  },
  {
    label: 'ราคาขายรวมในคลัง',
    value: 'total_price',
  },
];

const query = ({ id }) => ({
  place: id,
  fetchStockLot: true,
  fetchProcess: false,
  page: 1,
  size: appConfig?.maxFetchSize,
});

export default { columns, query };
